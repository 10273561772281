<template>
  <div>
    <div class="rightbox" style="width: 100%">
      <div class="topbox">
        <el-form ref="form" :model="queryParams" label-width="120px">
          <el-row>
            <el-col :span="5">
              <el-form-item
                :label="$t('N_I_124')"
                prop="memberCode"
                class="select"
              >
                <el-input v-model="queryParams.memberCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item
                :label="$t('CK_KS_14')"
                prop="memberName"
                class="select"
              >
                <el-input v-model="queryParams.memberName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('w_0141')"
                prop="teamCode"
                label-width="120px"
              >
                <el-date-picker
                  v-model="thetime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :range-separator="$t('w_0139')"
                  :start-placeholder="$t('CK_KS_4')"
                  :end-placeholder="$t('CK_KS_5')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div class="searchbox">
                <el-button class="btn2" @click="research">{{
                  $t("N_I_183")
                }}</el-button>
                <el-button class="btn1" @click="chongzhi">{{
                  $t("CK_KS_6")
                }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox">
        <el-table
          style="width: 100%"
          height="1210px"
          :data="tableData"
          :header-cell-style="{ background: '#EEEEEE' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            align="center"
            prop="memberCode"
            :label="$t('N_I_124')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="memberName"
            :label="$t('CK_KS_14')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="pkSettleGradeStr"
            :label="$t('CK_KS_15')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="creationTime"
            :label="$t('S_L_5')"
          ></el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="research"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import { getBiddingList, getchangeTypeList } from "@/api/bonus.js";
import { parentList } from "@/api/wallet";
export default {
  name: "Investment",
  components: {
    sidebarUserInfo,
    topBanner,
    pagination,
  },
  data() {
    return {
      navIndex: 0,
      changeTypeList: [],
      navList: [
        { name: this.$t("ENU_MENU_581"), path: "/pushList?id=6" },
        { name: this.$t("ENU_MENU_582"), path: "/investment?id=6" },
        { name: this.$t("ENU_MENU_583"), path: "/fansList?id=6" },
      ],
      marketList: [
        {
          text: this.$t("ENU_MENU_510"),
          id: 0,
          path: "/marketDynamics",
        },
        {
          text: this.$t("ENU_MENU_520"),
          id: 7,
          path: "/activeZone",
        },
        {
          text: this.$t("ENU_MENU_530"),
          id: 1,
          path: "/incomeDetail",
        },
        {
          text: this.$t("ENU_MENU_540"),
          id: 3,
          path: "/bonusSource",
        },
        // {
        //     text: "云代奖金",
        //     id: 2,
        //     path: "/",
        // },

        {
          text: this.$t("ENU_MENU_560"),
          id: 4,
          path: "/appraisal",
        },
        {
          text: this.$t("ENU_MENU_570"),
          id: 5,
          path: "/registration",
        },
        {
          text: this.$t("ENU_MENU_580"),
          id: 6,
          path: "/investment",
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        startCreateTime: "",
        endCreateTime: "",
        memberCode: "",
        memberName: "",
      },
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    this.research();
    this.getchangeTypeList();
  },
  methods: {
    getchangeTypeList() {
      getchangeTypeList().then((res) => {
        this.changeTypeList = res.data;
      });
    },
    clickHref(href) {
      this.$router.push({
        path: href,
      });
    },
    chongzhi() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        startCreateTime: "",
        endCreateTime: "",
        memberCode: "",
        memberName: "",
      };
      this.thetime = "";
      this.research();
    },
    changeArea(item) {
      this.specialArea = item.id;
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      if (this.thetime) {
        this.queryParams.startCreateTime = this.thetime[0];
        this.queryParams.endCreateTime = this.thetime[1];
      } else {
        this.queryParams.startCreateTime = "";
        this.queryParams.endCreateTime = "";
      }
      parentList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-table) {
  overflow: visible;
}
:deep(.el-table__header-wrapper) {
  position: sticky;
  top: 100px; //这个值根据实际情况而定
  z-index: 10;
}
::v-deep .el-range-editor.el-input__inner {
  width: 100%;
  // height: 32px;
  padding: 0 10px;
}
::v-deep .select .el-input__inner {
  // height: 32px;
}
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 20px 0 0 0;
  .nav-view {
    width: 60px;
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 47px 62px 47px 0;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 20px 0;
    padding: 20px 16px 20px 0;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    //box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    height: 1000px;
  }
}
</style>